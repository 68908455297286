import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { faq } from '../content/content.json'
import {Helmet} from 'react-helmet'
const FAQ = () => {
    return (
        <div className="faq-page">
            <Helmet title="EBNZ - FAQ" />
            <Header />
                <div className="faq-container">
                    {faq.map(question => (
                        <Question 
                            question={question.question}
                            answer={<div dangerouslySetInnerHTML={{__html: question.answer}}></div>}
                        />
                    ))}
                </div>
            <Footer />
        </div>
    )
}

const Question = ({question, answer}) =>{
    return (
        <div className="faq-question">
            <h4 className="question">{question}</h4>
            <p className="answer">{answer}</p>
        </div>
    )

}

export default FAQ;